.btn-default
{
  @include button-default();
}
// .btn-primary {
//     @include button-variant($primary, $primary, darken( $primary, 5% ));
// }

// .btn-outline-primary
// {
//   @include button-outlined( $primary );
// }
// .btn-outline-info
// {
//   @include button-outlined( $color-info );
// }
// .btn-outline-warning
// {
//   @include button-outlined( $color-warning );
// }
// .btn-outline-danger
// {
//   @include button-outlined( $color-danger );
// }

.btn-dual-primary
{
  @include button-dual( $primary );
}


.filter-gray
{
  background-color: #f4f4f4;
  color: #444;
  border-color: #ddd !important;

  font-weight: normal;
  -webkit-box-shadow: none;
  box-shadow: none;

  border-radius: 3px;
  border: 1px solid transparent;
}

label:not(.input-group-text) {
  margin-top: 1rem;
}
