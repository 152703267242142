.msg-info
{
    @include  message-variant( $darkblue );
}

.msg-warning
{
    @include  message-variant( #ffc107 );
}

.msg-danger
{
    @include  message-variant( $danger );
}

.msg-primary
{
    @include  message-variant( $primary );
}
