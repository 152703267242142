.select2-container {
    flex-grow: 1;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $primary;
}

.select2-container--default.select2-container--disabled .select2-selection--multiple {
    color: #6e707e !important;
    background-color: #eaecf4 !important;
    border: 1px solid #d1d3e2 !important;
}

.select2-container--default .select2-selection--multiple .select2-selection__choice {
    background-color: #f2f2f2;
    border: 1px solid #e4e4e4;
    border-radius: 1px;
    cursor: pointer;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
    border: solid #e4e4e4 1px;
}

.freephrase-column {
    ul.select2-selection__rendered {
        display: flex;
        flex-direction: column;
    }
}

.dropdown-menu {
    word-wrap: break-word;
    text-overflow: inherit;
    white-space: normal;
    max-width: 100%;

    ul {
        li {
            word-wrap: break-word;
            text-overflow: inherit;
            white-space: normal;

            .text {
                word-wrap: break-word;
                text-overflow: inherit;
                white-space: normal;
            }
        }
    }
}

.select2-container--default .select2-selection--multiple {
    border: 1px solid rgb(206, 212, 218) !important;
}

.select2-selection {
    overflow: hidden;
}
.select2-selection__rendered {
    white-space: normal !important;
    word-break: break-all;
}

select[readonly].select2 + .select2-container {
    pointer-events: none;
    touch-action: none;

    .select2-selection {
        background: #eaecf4;
        box-shadow: none;
    }

    .select2-selection__arrow,
    .select2-selection__clear {
        display: none;
    }
}
