.filtersContainer
{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.filtersField
{
  display: flex;
  flex-grow: 1;
  height: 48px;
  margin: 4px;

  font-size: 12px;
}
@media screen and (max-device-width: 768px) {
  .filtersField { flex: 0 100%; }
}
@media screen and (min-width: 1366px) {
  .filtersField { flex: 0 32%; }
}

.filtersContainer .form-control-sm
{
  padding: 0.15rem;
}
.filtersContainer .form-group
{
  padding-right: 1rem;
}
.filtersContainer label
{
  margin-bottom: 0.15rem;
}

.filtersField .filter-option
{
  font-size: 12px !important;
}

.filter-active
{
  color: #155724 !important;
  background-color: #e8f7ef !important;
  border-color: #c3e6cb !important;
}

.filter-active input
{
  color: #155724 !important;
}


// .filtersField .form-control+.input-group-addon:not(:first-child) {
//   border-left: 0;
// }

.filtersField input+.input-group-addon
{
  white-space: nowrap;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  background-color: #e9ecef;

  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  border-top-left-radius: 0rem;
  border-bottom-left-radius: 0rem;

  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;

  border-left: 0;
}

.filtersField input
{
  padding-left: 12px !important;
  padding-right: 12px !important;
}