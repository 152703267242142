@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;700;800&display=swap");

sub {
    font-variant-position: sub; /* enable */
    font-feature-settings: "subs" 1; /* low-level enable */
}

sup {
    font-variant-position: super; /* enable */
    font-feature-settings: "sups" 1; /* low-level enable */
}
sub {
    font-variant-position: sub; /* enable */
    font-feature-settings: "subs" 1; /* low-level enable */
}

sup {
    font-variant-position: super; /* enable */
    font-feature-settings: "sups" 1; /* low-level enable */
}

.thin {
    font-weight: 300;
}

.text {
    &--success {
        color: $text-positive;
    }
    &--danger {
        color: $text-negative;
    }
}

.required {
    color: red;
    margin-left: 0.5rem;
}
