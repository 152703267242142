.text-icon
{
  width: 42px;
  font-size: 9px;
  font-weight: 600;
  padding: 0!important;
}

.form-section-header
{
  font-size: 20px;
  font-weight:300;
}

.card
{
  & .card
  {
    border-radius: 0;
    border-bottom: 0;
    border-left: 0;
    border-right: 0;
    margin: -2px -20px 0px -20px;

    .card-header
    {
      color: $primary;
    }
  }
}

.audit-description {
    margin: 0 0 5px;
}