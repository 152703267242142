.query {
    @extend .alert;
    @extend .alert-primary;
    width: 100%;

    &__header {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        .question {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            width: 100%;

            &__title {
                width: 100%;
                margin-bottom: 0.5rem;
                font-weight: 400;
                line-height: 1.2;
                font-size: 1rem;

                &_enumeration {
                    font-style: normal;
                }

                &_content {
                    font-style: italic;
                }
            }

            &__subtitle {
                width: 100%;
                margin-bottom: 0.5rem;
                font-weight: 400;
                line-height: 1.2;
                font-size: 0.8rem;
            }
        }
    }

    &__content {
        @extend .alert-heading;
        width: 100%;
    }

    &__actions {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    &__comments {
        .comment {
            font-size: 0.8rem;
        }
    }
}
