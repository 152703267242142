.notification {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    font-size: 0.9rem;

    &--warning {
        color: #856404;
        background-color: #fff3cd;
        border-color: #ffeeba;
    }
}

// [data-notify="container"] {
// 	width: 100% !important;
// }
