.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 2px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--table {
        padding: 0.3rem 0.5rem;
        width: 90px;
    }
    &--selectpicker {
        vertical-align: middle;
    }

    &--danger {
        color: $badgeDangerTextColor;
        background-color: $badgeDangerBgColor;
    }

    &--success {
        color: $badgeSuccessTextColor;
        background-color: $badgeSuccessBgColor;
    }

    &--warning {
        color: $badgeWarningTextColor;
        background-color: $badgeWarningBgColor;
    }

    &--default {
        color: $gray-900;
        background-color: $gray-300;
    }

    &--primary {
        color: hsl(hue($primary), saturation($primary), 98%);
        background-color: hsl(hue($primary), 65%, lightness($primary));
    }

    &--info {
        color: $badgeInfoTextColor;
        background-color: $badgeInfoBgColor;
    }

    &--action {
        cursor: pointer;
    }
}
