table.table-bordered tbody th,
table.table-bordered tbody td {
    border-left-width: 0;
    border-bottom-width: 0;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    border-top: 1px solid #f4f4f4;
}

.table-bordered > thead > tr > th,
.table-bordered > thead > tr > td {
    border-bottom-width: 2px;
}

table.dataTable {
    border-collapse: collapse !important;
    border-spacing: 0 !important;
}

.table-bordered thead td,
.table-bordered thead th {
    border-bottom-width: 0;
}
.dataTable thead th {
    vertical-align: bottom;
    border-bottom: 0;
}

.dataTable tr.warning {
    background-color: $warningBgColor;
}

.dataTable tr.secondary {
    background-color: #e2e3e5;
}

.dataTable th {
    font-size: 14px;
    color: #5a5c69 !important;
    border: 0;
}
.dataTable td {
    font-size: 14px;
    border: 0;
    vertical-align: middle;
}

.status-badge {
    border-radius: 0;
    font-size: 13px;
    line-height: 13px;
    padding: 0.375rem 0.5625rem;
    font-weight: 400;
    cursor: pointer;
    text-align: center;
}

.sds-phrase--scripted {
    background-color: #f4f4f4;
}

.positive {
    color: #fff;
    background-color: $primary;
}

.negative {
    color: #fff;
    background-color: #fc5661;
}

.accepted {
    color: #fff;
    background-color: #54628c;
}

.awaiting {
    color: #fff;
    background-color: #f2c685;
}

.hvr-backward,
.hvr-forward {
    display: inline-block;
    vertical-align: middle;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 0 1px transparent;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: transform;
    transition-property: transform;
}

.hvr-forward:active,
.hvr-forward:focus,
.hvr-forward:hover {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
}

.hvr-backward:active,
.hvr-backward:focus,
.hvr-backward:hover {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
}

.page-link {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529 !important;
    border-color: #f3f3f3;
}

.page-item.active .page-link {
    background-color: $primary;
    border-color: $primary;
    color: #fff !important;
}

.page-item {
    z-index: 2;
    text-decoration: none;
    background-color: transparent !important;
    border-color: transparent !important;
    color: #fff !important;
}

.page-link:hover {
    z-index: 5;
    text-decoration: none;
    background-color: $primary !important;
    border-color: $primary !important;
    color: #fff !important;
}

.card-header-white {
    background-color: rgba(0, 0, 0, 0) !important;
    border-bottom: 1px solid rgba(0, 0, 0, 0) !important;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff !important;
    background-color: $primary !important;
}

table.dataTable {
    width: 100%;
}

.small-input {
    height: 30px !important;
    line-height: 30px !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    padding: 5px 10px !important;
    font-size: 12px !important;

    color: #555 !important;
    background-color: #fff !important;
    background-image: none !important;
    border: 1px solid #d2d6de !important;
}

.dataTables_length {
    text-align: left !important;
}
.dataTables_filter {
    text-align: right !important;
}

.input-group-addon {
    width: 42px !important;
}

.input-group .large-selectpicker {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
    display: table;
    table-layout: fixed;
}

.fit-column {
    width: 1%;
    white-space: nowrap;
}

.table-striped tbody tr:nth-of-type(odd).secondary {
    background-color: #e2e3e5 !important;
}

.table-striped tbody tr:nth-of-type(odd).warning {
    background-color: $warningBgColor !important;
}

.dataTables_wrapper .pagination {
    justify-content: flex-end !important;
}

.datatable__buttons {
    display: flex;

    .btn {
        margin: 0 0 0 5px;
    }
}

.inactive td {
    text-decoration: line-through;
}
