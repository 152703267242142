@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.animated--fade-in {
    animation-name: fadeIn;
    animation-duration: 200ms;
    animation-timing-function: opacity cubic-bezier(0,1,.4,1);
}