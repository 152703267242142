table#cellAllocation {
    margin: 0;
    border: 3px solid #e3e6f0;

    thead {
        th {
            height: 50px;
            color: #6e707e;
            background-color: #eaecf4;
            border-color: #e3e6f0;
            border-style: none;
        }
    }

    tbody {
        tr {
            th {
                width: 50px;
                white-space: nowrap;
                color: #6e707e;
                background-color: #eaecf4;
                border-color: #e3e6f0;
                border-style: none;
            }

            td {
                &.cell {
                    min-height: 80px;
                    min-width: 130px;
                    padding: 0px;
                    border: 2px solid #e6e6e6;
                    font-size: 8px;

                    &:hover {
                        cursor: pointer;
                        background-color: rgba(0, 0, 0, 0.025);
                    }

                    &--selected {
                        color: #004085;
                        background-color: #cce5ff;
                        border-color: #b8daff;

                        &:hover {
                            background-color: darken(#cce5ff, 10%);
                        }

                        .cell-content {
                            color: #004085;

                            tr {
                                border-color: #b8daff !important;
                            }
                        }
                    }

                    table {
                        &.cell-content {
                            margin: 0;

                            tr {
                                &:not(:last-child) {
                                    border-bottom: 1px solid #e3e6f0;
                                }
                            }

                            td {
                                border-style: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .trial {
        &--positive {
            color: #155724;
            background-color: #f1f9f3;
            border-color: #c3e6cb;
        }

        &--negative {
            color: #721c24;
            background-color: #fceeef;
            border-color: #f5c6cb;
        }
    }
}
