// Global component styles
html {
  position: relative;
  min-height: 100%;
}

body {
  height: 100%;

  font-family: $font-family-sans-serif;
  color: $gray-600;
}

a {
  &:focus {
    outline: none;
  }
}

// Main page wrapper
#wrapper {
  display: flex;
  #content-wrapper {
    background-color: $gray-100;
    width: 100%;
    overflow-x: hidden;
    #content {
      flex: 1 0 auto;
    }
  }
}

// Set container padding to match gutter width instead of default 15px
.container,
.container-fluid {
  padding-left: $grid-gutter-width;
  padding-right: $grid-gutter-width;
}
