.bg-login-brand {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: $primary;
  background-position: center;
  background-size: cover;

  color: #FFF;
  
  font-size: 256px;
  height: 100%;
  min-height: 385px;
}


.loginbox
{
  display: flex;
  justify-content: center!important;
  align-items: center;

  height: 80vh;
  
  form
  {
    .custom-checkbox.small {
      label {
        line-height: 1.5rem;
      }
    }
  
    .form-control-user {
      font-size: 0.8rem;
      border-radius: 10rem;
      padding: 1.5rem 1rem;
    }
  
    button[type='submit'] {
      font-size: 0.8rem;
      border-radius: 10rem;
      padding: 0.75rem 1rem;
    }
  
  }
}

// Fix Google's Yellowish  Autocomplete
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
