.attachment {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 0.5rem 1rem;

    > svg {
        height: 1.3rem;
    }

    p {
        flex-grow: 1;
        margin: 0;
        font-size: 0.8rem;
    }

    &__buttons {
        display: flex;
        gap: 5px;

        .btn {
            line-height: 1.1;

            svg {
                height: 1rem;
            }
        }
    }
}