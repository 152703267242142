.tag {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    text-align: center;
    white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;

    &--table {
        padding: 0.3rem 0.5rem;
    }
    &--selectpicker {
        vertical-align: middle;
    }
    &--select2 {
    }

    &--danger {
        color: $badgeDangerBgColor;
        border: 1px solid $badgeDangerBgColor;
        background-color: transparent;
    }
    &--success {
        color: $badgeSuccessTextColor;
        border: 1px solid $badgeSuccessTextColor;
        background-color: transparent;
    }
    &--warning {
        color: $badgeWarningTextColor;
        border: 1px solid $badgeWarningTextColor;
        background-color: transparent;
    }
    &--default {
        color: $gray-900;
        border: 1px solid $gray-900;
        background-color: transparent;
    }
    &--primary {
        color: $primary;
        border: 1px solid $primary;
        background-color: transparent;
    }
    &--info {
        color: $badgeInfoTextColor;
        border: 1px solid $badgeInfoTextColor;
        background-color: transparent;
    }
}