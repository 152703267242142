// Override Bootstrap default variables here
// Do not edit any of the files in /vendor/bootstrap/scss/!

// Color Variables
// Bootstrap Color Overrides
$primary: #5f32b2 !default;
$info: #22509a !default;
$warning: #f6c23e !default;
$danger: #dc3545 !default;

$white: #fff !default;
$gray-100: #f8f9fc !default;
$gray-200: #eaecf4 !default;
$gray-300: #dddfeb !default;
$gray-400: #d1d3e2 !default;
$gray-500: #b7b9cc !default;
$gray-600: #858796 !default;
$gray-700: #6e707e !default;
$gray-800: #5a5c69 !default;
$gray-900: #3a3b45 !default;
$black: #000 !default;

$blue: #4e73df !default;
$darkblue: #22509a !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #e74a3b !default;
$orange: #fd7e14 !default;
$yellow: #f6c23e !default;
$green: #1cc88a !default;
$teal: #20c9a6 !default;
$cyan: #36b9cc !default;

$danger: #dc3545;

// Custom Colors
$brand-google: #ea4335;
$brand-facebook: #3b5998;

$color-info: #22509a;
$color-warning: #f6c23e;
$color-danger: #dc3545;

// scrollbar
$scrollbarTrack: #f5f5f5;
$scrollbarThumbHover: $primary;

$text-positive: #2e8b57;
$text-negative: #fc5661;

// Set Contrast Threshold
$yiq-contrasted-threshold: 195 !default;

// Typography
$body-color: $gray-600 !default;

$font-family-sans-serif: "Open Sans" !default;
$font-family-fontawesome: 'Font Awesome 6 Free';

$font-weight-light: 300 !default;
// $font-weight-base: 400;
$headings-font-weight: 400 !default;

// Shadows
$box-shadow-sm: 0 0.125rem 0.25rem 0 rgba($gray-900, .2) !default;
$box-shadow: 0 0.15rem 1.75rem 0 rgba($gray-900, .15) !default;
// $box-shadow-lg: 0 1rem 3rem rgba($black, .175) !default;

// Borders Radius
$border-radius: 0.35rem !default;
$border-color: darken($gray-200, 2%);

// Spacing Variables
// Change below variable if the height of the navbar changes
$topbar-base-height: 4.375rem;
// Change below variable to change the width of the sidenav
$sidebar-base-width: 15rem;
// Change below variable to change the width of the sidenav when collapsed
$sidebar-collapsed-width: 6.5rem;

// Card
$card-cap-bg: $gray-100;
$card-border-color: $border-color;

// Adjust column spacing for symmetry
$spacer: 1rem;
$grid-gutter-width: $spacer * 1.5;

// Transitions
$transition-collapse: height .15s ease !default;

// Dropdowns
$dropdown-font-size: 0.85rem;
$dropdown-border-color: $border-color;


// badges
$badgeDangerTextColor: #fff;
$badgeDangerBgColor: #fc5661;
$badgeSuccessTextColor: #fff;
$badgeSuccessBgColor: #2e8b57;
$badgeWarningTextColor: #856404;
$badgeWarningBgColor: #fff3cd;
$badgeInfoTextColor: #084298;
$badgeInfoBgColor: #cfe2ff;


// modals
$modalHeaderBgColor: $primary;
$modalHeaderTextColor: #FFFFFF;
$modalHeaderCloseTextColor: #FFFFFF;
$modalHeaderCloseTextShadowColor: #000;
$modalFooterBgColor: #FFFFFF;


$theme-colors: (
  urteste: $primary
);

// Pretty-Checkbox
$pretty--color-primary: $primary !default;

$dangerTextColor: #721c24;
$dangerBgColor: #f8d7da;
$dangerBorderColor: #f5c6cb;
$successTextColor: #155724;
$successBgColor: #d4edda;
$successBorderColor: #c3e6cb;
$warningTextColor: #856404;
$warningBgColor: #fff3cd;
$warningBorderColor: #ffeeba;