.modal-header {
    background-color: $modalHeaderBgColor;
    color: $modalHeaderTextColor;

    .modal-title {
        font-size: 1rem;
    }

    .close {
        outline: none !important;
        color: $modalHeaderCloseTextColor;
        text-shadow: 0 1px 0 $modalHeaderCloseTextShadowColor;
    }
}

.modal-footer {
    background-color: $modalFooterBgColor;
}

.modal-loader {
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    &.off {
        display: none;
    }

    .spinner-border {
        color: $primary;
    }

    p {
        font-size: 0.9rem;
        margin: 1rem 0 0;
    }
}
