.list-group-item
{
  padding: 10px 10px !important;
  cursor: n-resize !important;
}

.item--default
{
  color: #818182 !important;
  background-color: #f4f5f5 !important;
  border-color: #e9eaec !important;
}
.item--scripted
{ 
  color: #004085 !important;
  background-color: hsl(211, 100%, 96%) !important;
  border-color: hsl(211, 100%, 92%, )!important;
}
.item--active
{ 
  color: #155724;
  background-color: hsl(134, 41%, 96%)!important;
  border-color: hsl(134, 41%, 92%)!important;
}