.pretty-small
{
  font-size: 12px !important;
  div
  {
    label
    {
      font-size: 12px;
    }
  }
}

.pretty-normal
{
  font-size: 16px !important;
  div
  {
    label
    {
      font-size: 16px;
    }
  }
}
