.modal-content
{
  background-color: transparent !important;
}

.modal-header
{
  color: #FFFFFF;
  background-color: $primary !important;
}

.modal-body, .modal-footer
{
  background-color: #FFFFFF !important;
}

.modal-full {
  max-width: 95%;
}