.daterangepicker td.active, .daterangepicker td.active:hover {
    background-color: lighten($primary, 15%);
    border-color: transparent;
    color: #fff;
}

.daterangepicker td.in-range:not(.active) {
    background-color: hsl( hue($primary), saturation($primary), 98% );
    border-color: transparent;
    color: #000;
    border-radius: 0;
}