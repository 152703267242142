.bootstrap-select {
    button.dropdown-toggle {
        padding-left: 0.75rem;

        color: #495057;

        // font-weight: 600;
        font-size: 1rem; // 14px

        border: 1px solid #ced4da;
        background-color: #ffffff;
    }

    .top-right-border-radius > div > button.dropdown-toggle {
        color: #495057;
        // font-weight: 600;
        font-size: 1rem;
        border: 1px solid #ced4da;
        background-color: #ffffff;
    }
}

/* Apply styles to selectpicker elements with readonly attribute */
.selectpicker[readonly] {
    pointer-events: none;
    background-color: #e9ecef; /* Light grey background */
    cursor: not-allowed;
}

// .bootstrap-select.dropdown:has(.selectpicker[readonly]) {
//     pointer-events: none;
//     background-color: #e9ecef; /* Light grey background */
//     cursor: not-allowed;
// }

/* Ensure the dropdown toggle also reflects the readonly state */
.selectpicker[readonly] + .dropdown-toggle {
    pointer-events: none;
    background-color: #e9ecef !important; /* Match the input's background color */
}

/* Optional: You can add specific styles for select2 if needed */
//   .select2-container--disabled .select2-selection,
//   .select2-container--disabled .select2-selection__rendered {
//     background-color: #e9ecef;
//     cursor: not-allowed;
//   }
