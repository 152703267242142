@mixin message-variant( $color )
{
  background-color: hsl( hue($color), saturation($color), 98% );

  border-color: lighten( $color, 15% );
  color: hsl( hue($color), 65%, lightness($color) );
  
  border-radius: 4px;
  border-style: solid;
  border-width: 0 0 0 4px;

  padding: 1.25em 1.5em;
}

// @mixin button-outlined( $color )
// {
//   color: $color !important;
//   background-color: transparent;
//   background-image: none;
//   border-color: $color!important;

//   cursor: pointer;

//   &:hover
//   {
//     color: #FFFFFF !important;
//     background-color: $color!important;
//     border-color: $color!important;
//   }
// }

@mixin button-dual( $color )
{
  background-color: #ABABAB;
  border-color: #ABABAB;

  color: #FFFFFF;

  cursor: pointer;
  vertical-align: middle;

  &:hover
  {
    background-color: $color;
    border-color: $color;

    color: #FFF;
  }
}

@mixin button-default()
{
  background-color: #ABABAB;
  border-color: #ABABAB;

  color: #FFFFFF;

  &:hover
  {
    background-color: #999999;
    border-color: #999999;

    color: #FFF;
  }
}
