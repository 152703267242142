@import "abstracts/variables.scss";

// Bootstrap
// @import "../../../node_modules/bootstrap/scss/bootstrap-grid";
// @import "../../../node_modules/bootstrap/scss/functions";
// @import "../../../node_modules/bootstrap/scss/variables";
// @import "../../../node_modules/bootstrap/scss/mixins";
// @import "../../../node_modules/bootstrap/scss/utilities";
// @import "../../../node_modules/bootstrap/scss/close";
// @import "../../../node_modules/bootstrap/scss/dropdown";
// @import "../../../node_modules/bootstrap/scss/buttons";
// @import "../../../node_modules/bootstrap/scss/modal";
// @import "../../../node_modules/bootstrap/scss/alert";
// @import "../../../node_modules/bootstrap/scss/tables";
// @import "../../../node_modules/bootstrap/scss/pagination";
// @import "../../../node_modules/bootstrap/scss/forms";
// @import "../../../node_modules/bootstrap/scss/grid";
// @import "../../../node_modules/bootstrap/scss/spinners";
@import "../../../node_modules/bootstrap/scss/bootstrap";

// Bootstrap-Select
@import "../../../node_modules/bootstrap-select/sass/variables";
@import "../../../node_modules/bootstrap-select/sass/bootstrap-select";
@import "../../../node_modules/select2/dist/css/select2.min.css";

@import "../../../node_modules/daterangepicker/daterangepicker.css";
@import "../../../node_modules/pretty-checkbox/src/pretty-checkbox.scss";

// International Telephone Input
@import "../../../node_modules/intl-tel-input/build/css/intlTelInput.css";

.iti__flag {
  background-image: url("../../../node_modules/intl-tel-input/build/img/flags.png");
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .iti__flag {
    background-image: url("../../../node_modules/intl-tel-input/build/img/flags@2x.png");
  }
}

.iti {
  width: 100%;
}
//

// Animate.css
@import "../../../node_modules/animate.css/animate.css";

@import "abstracts/functions.scss";
@import "abstracts/animations.scss";
@import "abstracts/utilities.scss";
@import "abstracts/mixins.scss";

// @import "base/reset.scss";
@import "base/global.scss";
@import "base/typography.scss";

@import "components/forms.scss";
@import "components/buttons.scss";
@import "components/dropdowns.scss";
@import "components/messages.scss";
@import "components/notifications.scss";

@import "components/cards.scss";
@import "components/badges.scss";
@import "components/tags.scss";
// @import "components/lang-select.scss";
@import "components/modals.scss";
@import "components/forms.scss";
@import "components/forms/queries.scss";
@import "components/filters.scss";
@import "components/scroll-to-top.scss";
@import "components/cell-allocation.scss";
@import "components/attachments.scss";
@import "components/bugReport.scss";

@import "layout/global.scss";
@import "layout/topbar.scss";
@import "layout/sidebar.scss";
@import "layout/footer.scss";

@import "pages/login.scss";
@import "pages/error.scss";

// @import "../../../node_modules/bootstrap-select/sass/bootstrap-select";
// @import "../../../node_modules/daterangepicker/daterangepicker.css";

@import "overrides/breadcrumb.scss";
@import "overrides/datatables.scss";
@import "overrides/modals.scss";
@import "overrides/forms.scss";
@import "overrides/card.scss";
@import "overrides/nav.scss";
@import "overrides/collapse.scss";
@import "overrides/checkbox.scss";
@import "overrides/list.scss";
@import "overrides/pretty-checkbox.scss";
@import "overrides/select2.scss";
@import "overrides/bootstrap-select.scss";
@import "overrides/daterangepicker.scss";
