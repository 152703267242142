.scroll-to-top {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
    display: none;
    width: 2.75rem;
    height: 2.75rem;
    text-align: center;
    color: $white;
    background: fade-out($gray-800, .5);
    line-height: 46px;
    &:focus,
    &:hover {
      color: white;
    }
    &:hover {
      background: $gray-800;
    }
    i {
      font-weight: 800;
    }
  }