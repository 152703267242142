.error-page {
	background-color: #5f32b2;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: row;

	&__container {
		display: flex;
		flex-direction: row;
	}

	&__logo {
		border-right: 1px solid rgba(255, 255, 255, 0.15);
		padding: 0 20px 0 0;
		display: flex;
		align-items: center;

		img {
			width: 250px;
		}
	}

	&__main {
		padding: 0 20px;

		h1 {
			font-size: 7rem;
			color: #d9d9da;
			text-shadow: 0 0 15px #414141;
			font-weight: 800;
			letter-spacing: 5px;
			line-height: 6rem;
			text-align: center;
		}

		.code {
			font-size: 1rem;
			color: #d9d9da;
			text-shadow: 0 0 15px #414141;
			text-align: center;

			span {
				color: red;
			}
		}

		.message {
			font-size: 1.5rem;
			color: #d9d9da;
			text-shadow: 0 0 15px #414141;
			text-align: center;
			width: 350px;
		}
	}
}
