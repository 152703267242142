.urteste-card-header {
    display: flex;
    flex-direction: row;

    justify-content: space-between;

    & ul {
        margin-top: 10px;
    }
}

.card-body > table.table-form {
    margin-bottom: 0;
}

.card-body:has(> table.table-form) {
    padding: 0;
}

// .card-body:has(.table-form) + .card-header {
//     border-bottom: none;
// }
// .card-header + .card-body:has(.table-form) {
//     border-bottom: none;
// }

table.table-form tr th:first-child,
table.table-form tr td:first-child {
    padding-left: 1.2rem; /* Adjust the padding value as needed */
}

table.table-form thead tr th {
    background-color: #f8f9fc;
    font-weight: normal;
}
