.disable-padding {
    padding: 0 !important;
}

.table-components {
    border-top: 0;
    border-left: 0;
    border-right: 0;

    &>thead>tr>th {
        border-top: 0;
        border-left: 0;
        border-right: 0;

        background-color: hsl(225, 40%, 98%);
    }
}

.table-hover tbody tr:hover {
    cursor: pointer;

    color: #858796;
    background-color: hsl(146, 30%, 98%);
}

.input-group-text {
    width: 42px !important;
}

button:disabled {
    cursor: not-allowed;
    pointer-events: all !important;
}

.nav-tabs .nav-link.disabled {
    opacity: 0.85 !important;
}

label.visibility-checkbox {
    margin: 0px;
    cursor: pointer;
}

.visibility-checkbox input {
    display: none;
}

.visibility-checkbox input~span {
    color: #333;
    cursor: pointer;
    padding: 6px;
    border-radius: 2px;
    font-weight: 200;
}

.visibility-checkbox input~.checked {
    display: none;
}

.visibility-checkbox input:checked~.checked {
    display: inline-block;
}

.visibility-checkbox input:checked~.unchecked {
    display: none;
}

.visibility-checkbox input:disabled~i {
    color: #999;
    cursor: not-allowed;
}


label.page-break-checkbox {
    margin: 0px;
    cursor: pointer;
}

.page-break-checkbox input {
    display: none;
}

.page-break-checkbox input~span {
    color: #333;
    cursor: pointer;
    padding: 6px;
    border-radius: 2px;
    font-weight: 200;
}

.page-break-checkbox input~.checked {
    display: none;
}

.page-break-checkbox input:checked~.checked {
    display: inline-block;
}

.page-break-checkbox input:checked~.unchecked {
    display: none;
}

.page-break-checkbox input:disabled~i {
    color: #999;
    cursor: not-allowed;
}

.substance-details {
    .substance-details-header {
        font-size: 14px;
        font-weight: bolder;
        text-transform: uppercase;
    }

    p {
        margin-top: 2px !important;
        margin-bottom: 2px !important;
        font-size: 11px !important;
    }
}

.input-group {
    &.input-error {
        .select2-selection {
            border: 1px solid $dangerBorderColor !important;
            background-color: $dangerBgColor;
        }

        .form-control {
            border: 1px solid $dangerBorderColor;
            color: $dangerTextColor;
        }

        .input-group-text {
            color: $dangerTextColor;
            background-color: $dangerBgColor;
            border: 1px solid $dangerBorderColor;
        }
    }

    &:has(.attachment-file) {
        font-size: 0.8rem;
        display: flex;
        align-items: center;
    }

    .attachment-file {
        &::file-selector-button {
            font-size: 0.8rem;
            margin: 0 0.5rem;
        }
    }
}