footer.sticky-footer {
  padding: 1.4rem 0;
  flex-shrink: 0;
  .copyright {
    line-height: 1;

    // font-size: 0.8rem;
    font-size: 12px;

    color: #b2b1ae;
    text-shadow: 1px 1px #FFFFFF;
  }
}

body.sidebar-toggled {
  footer.sticky-footer {
    width: 100%;
  }
}
